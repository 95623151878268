
  <template>
  <b-container fluid class="account-headers accounts-tabs-height">
    <b-row>
      <b-col cols="1" class="text-center">
        <b-img src="/img/account-icons.svg"></b-img>
      </b-col>
      <b-col cols="11">
        <b-row>
          <b-col cols="12">
            <h2>{{$t("profile.infotexts.share-title")}}</h2>
            <p>
              {{$t("profile.infotexts.share")}}
            </p>
            <hr />
          </b-col>
          <!-- <b-col cols="12" class="account-sub-header pt-3">
            <h3>E-Mail Signature</h3>
            <p>
              Simply copy and past this image at the bottom of your mail
              signature located in your mail preferencess. When clicked it will
              automaitcally direct to your public use profile.
            </p>
          </b-col> -->
          <!-- <b-col cols="12">
            <b-img
              src="/img/mail-signature-icon.png"
              fluid
              alt="Responsive image"
            ></b-img>
            <router-link to="" class="copy-btn ml-3 mr-2">Copy</router-link>
            <b-img
              src="/img/copy-mail-icon.png"
              fluid
              alt="Responsive image"
            ></b-img>

            <hr />
          </b-col> -->
          <b-col cols="8">
            <h3 class="mb-5">{{$t("profile.contents.myprofile.share.link-title")}}</h3>
            <b-input-group id="input-group-1" class="copy-link-input">
              <template #append>
                <b-button  class="copy-button"@click="setCopyLink()" variant="none">{{$t("buttons.linkcopy")}}</b-button>
              </template>
              <b-form-input
                id="link-input"
                type="text"
                :value="'https://app.connectter.com/app/user/profile/' + GET_USER_INFO.uid"
                disabled
              ></b-form-input>
            </b-input-group>
          </b-col>
        </b-row>
        <b-row class="connect-social-account">
          <b-col cols="12">
            <hr />
            <h3>{{$t("profile.contents.myprofile.share.links-title")}}</h3>
            <p>{{$t("profile.contents.myprofile.share.links-text")}}</p>
            <b-row class="mb-5">
              <b-col cols="12">
                <h4>LinkedIn</h4>
              </b-col>
              <b-col cols="8">
                <b-form-input
                  type="text"
                  :placeholder="$t('profile.contents.myprofile.share.ph1')" 
                  required
                  v-model="form.linkedin"
                  :state="states.linkedin"
                >
                </b-form-input>
              </b-col>
              <b-col cols="4" class="text-left">
                <b-button
                  v-if="!!checkLink('LINKEDIN')"
                  :variant="hovers.linkedin === false ? 'success' : 'danger'"
                  @mouseleave="() => hovers.linkedin = false"
                  @mouseover="() => hovers.linkedin = true"
                  @click="disconnect('LINKEDIN')"
                  style="width:170px;"
                >
                    {{ hovers.linkedin === false ? $t("buttons.connected") : 'Disconnect' }}
                </b-button>
                <b-button 
                    v-else 
                    variant="primary"
                    @click="connect('LINKEDIN')"
                    :disabled="loading || states.linkedin == null || states.linkedin == false"
                    style="width:170px;"
                >
                    {{$t("buttons.connect")}}
                </b-button>
              </b-col>
            </b-row>

            <b-row class="mb-5">
              <b-col cols="12">
                <h4>Twitter</h4>
              </b-col>
              <b-col cols="8">
                <b-form-input
                  type="text"
                  :placeholder="$t('profile.contents.myprofile.share.ph2')" 
                  required
                  v-model="form.twitter"
                  :state="states.twitter"
                ></b-form-input>
              </b-col>
              <b-col cols="4" class="text-left">
                <b-button
                  v-if="!!checkLink('TWITTER')"
                  :variant="hovers.twitter === false ? 'success' : 'danger'"
                  @mouseleave="() => hovers.twitter = false"
                  @mouseover="() => hovers.twitter = true"
                  @click="disconnect('TWITTER')"
                  style="width:170px;"
                >
                    {{ hovers.twitter === false ? $t("buttons.connected") : 'Disconnect' }}
                </b-button>
                <b-button 
                    v-else 
                    variant="primary" 
                    @click="connect('TWITTER')"
                    :disabled="loading || states.twitter == null || states.twitter == false"
                     style="width:170px;"
                >
                    {{$t("buttons.connect")}}
                </b-button>
              </b-col>
            </b-row>

            <b-row class="mb-5">
              <b-col cols="12">
                <h4>Facebook</h4>
              </b-col>
              <b-col cols="8">
                <b-form-input
                  type="text"
                  :placeholder="$t('profile.contents.myprofile.share.ph3')" 
                  required
                  v-model="form.facebook"
                  :state="states.facebook"
                ></b-form-input>
              </b-col>
              <b-col cols="4" class="text-left">
                <b-button
                  v-if="!!checkLink('FACEBOOK')"
                  :variant="hovers.facebook === false ? 'success' : 'danger'"
                  @click="disconnect('FACEBOOK')"
                  @mouseleave="() => hovers.facebook = false"
                  @mouseover="() => hovers.facebook = true"
                  style="width:170px;"
                >
                    {{ hovers.facebook === false ? $t("buttons.connected") : 'Disconnect' }}
                </b-button>
                <b-button 
                    v-else 
                    variant="primary"
                    @click="connect('FACEBOOK')"
                    :disabled="loading || states.facebook == null || states.facebook == false"
                     style="width:170px;"
                >
                     {{$t("buttons.connect")}}
                </b-button>
              </b-col>
            </b-row>
          </b-col>
        </b-row>

        <b-row class="share-us">
          <b-col cols="12">
            <h5>{{$t("profile.contents.myprofile.share.share-us")}}</h5>
          </b-col>
          <b-col cols="12">
            <div class="sharethis-inline-share-buttons"></div>
            <b-row>
              <b-col cols="4">
                <b-row>
                  <b-col cols="4">
                    <a
                      href="https://twitter.com/share?url=https://connectter.com/"
                    >
                      <b-img src="/img/share-twitter.svg"></b-img>
                    </a>
                  </b-col>
                  <b-col cols="4">
                    <a
                      href="https://www.linkedin.com/cws/share?url=https://connectter.com/"
                    >
                      <b-img src="/img/share-linkedin.svg"></b-img>
                    </a>
                  </b-col>
                  <b-col cols="4">
                    <a
                      href="https://www.facebook.com/sharer.php?u=https://connectter.com/"
                    >
                      <b-img src="/img/share-facebook.svg"></b-img>
                    </a>
                  </b-col>
                </b-row>
              </b-col>
            </b-row>
          </b-col>
        </b-row>
      </b-col>
    </b-row>
  </b-container>
</template>

<script>
import SOCIAL_ACCOUNTS from '@/graphql/me/profile/socialAccounts.graphql';
import CONNECT from '@/graphql/me/profile/connectSocialAccount.graphql';
import DISCONNECT from '@/graphql/me/profile/disconnectSocialAccount.graphql';
import _ from 'lodash';
export default {
  regexes: {
    FACEBOOK: /(?:http|https):\/\/(www\.)?facebook.com\/+./i,
    TWITTER: /(?:http|https):\/\/(www\.)?twitter.com\/+./i,
    LINKEDIN: /(?:http|https):\/\/(www\.)?linkedin.com\/in\/+./i,
    ZOOM: /(?:http|https):\/\/(www\.)?zoom.us/i
  },
  data() {
    return {
        loading: false,
        accounts: [],
        hovers: {
            linkedin: false,
            facebook: false,
            twitter: false
        },
        states: {
            linkedin: null,
            facebook: null,
            twitter: null
        },
        form: {
            linkedin: null,
            facebook: null,
            twitter: null
        }
    };
  },
  watch: {
      form: {
          handler(form) {
              for (let key in form) {
                  if (form[key] == null || form[key] == '') {
                      this.states[key] = null;
                      continue;
                  }

                  let check = this.$options.regexes[_.upperCase(key)].test(form[key]);
                  this.states[key] = check;
              }
          },
          deep: true
      }
  },
  methods: {
      selectText(element) {
        let range;
        if (document.selection) {
            range = document.body.createTextRange();
            range.moveToElementText(element);
            range.select();
        } else if (window.getSelection) {
            range = document.createRange();
            range.selectNode(element);
            window.getSelection().removeAllRanges();
            window.getSelection().addRange(range);
        }
      },
      setCopyLink() {
          this.selectText(document.querySelector('#link-input'));
          document.execCommand('copy');
      },

      checkLink(type) {
          return _.get(_.find(this.accounts, x => x.type === type), 'link', null);
      },

      async connect(type) {
          this.loading = true;
          if (this.states[_.lowerCase(type)] !== true) {
              this.loading = false;    
              return;
          }

          let url = `${this.API_DOMAIN}/auth/${_.lowerCase(type)}?token=${this.GET_USER_INFO.token}`;
          let popup = window.open(url, '_blank', 'status=1,width=800,height=500');
          let id = null;
          let timer = async () => {
              if (popup && popup.closed && this.form[_.lowerCase(type)].link) {
                  clearInterval(id);
                  popup = null;
                  let params = {
                      profile: {
                          type: type,
                          link: this.form[_.lowerCase(type)]
                      }
                  };

                  try {
                      await this.$apollo.mutate(CONNECT, params);
                      await this.loadSocialAccounts();
                  } catch (error) {
                      return this.$bvToast.toast(this.$t(error.message), {
                        title: this.$t("Toaster.error-title"),
                        variant: "secondary",
                        autoHideDelay: 1350,
                      });
                  } finally {
                      this.loading = false;
                  }
            }
          };

        id = setInterval(timer, 250);
      },

      async disconnect(type) {
          try {
              this.loading = true;
              await this.$apollo.mutate(DISCONNECT, { type });
          } catch (error) {
              alert(error.message);
          } finally {
              await this.loadSocialAccounts();
              this.loading = false;
          }
      },
    
      async loadSocialAccounts() {
        try {
            this.loading = true;
            let types = ['FACEBOOK', 'LINKEDIN', 'TWITTER'];
            let params = {
                filters: {
                    type: { in: types }
                },
                cursor: null,
                limit: 100,
            };

            let data = await this.$apollo.query(SOCIAL_ACCOUNTS, params);
            this.$set(this, 'accounts', data("**.accountList.list"));
            this.form.linkedin = this.checkLink('LINKEDIN');
            this.form.facebook = this.checkLink('FACEBOOK');
            this.form.twitter = this.checkLink('TWITTER');
        } catch (error) {
            return this.$bvToast.toast(this.$t(error.message), {
          title: this.$t("Toaster.error-title"),
          variant: "secondary",
          autoHideDelay: 1350,
        });
        } finally {
            this.loading = false;
        }
    },
  },

  created() {
    this.loadSocialAccounts();
  }
};
</script>